'use strict';

/*******************************************************************************************/
class UxrPopupNetworkList20 extends React.Component {
	constructor(props) {
		super(props);
		
		this.onJQueryMount = this.onJQueryMount.bind(this);
		this.onClick_listEntry = this.onClick_listEntry.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		let self = this;
		
		let props = {};
		props['ref'] = this.onJQueryMount;
		
		let svgMarkerMarkup = (
			<img className="hitListMarker hitListMarkerNetzwerk" src="/application/themes/rememberingKlassisch/img/marker_netzwerk_babyblue.png" />
		);
		//svgMarker_netzwerk();
		
		let hitList = [];
		_.forEach(GLOBALS.popupsData, function(p, id) {
			if (p.type !== 'v1_netzwerk') {
				return;
			}
			let props = {
				'key': id,
				'data-id': id,
				'onClick': self.onClick_listEntry,
			}
			hitList.push(
				<div className="hitListEntry" {...props}>
					{svgMarkerMarkup}
					<span>{p.title}</span>
					<div className="flowClear" />
				</div>
			);
		});
		
		return (
			<div className="popupContainer popupContainerList" {...props}>
				<div className="popupContainer_controls">
					<div className="popupContainer_iconClose fa fa-close"></div>
				</div>
				
				<div className="popupContainer_content">
					<span className="writeBold alignCenter">{ UxrRenderDuoLangLabel("Netzwerk", "Network") }</span>
					
					<div className="content">
						<div className="content_list active">
						<div className="flowRight writeCaps">alle einträge</div>
						<div className="flowClear"></div>
							<div className="scrollable">
								{hitList}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onJQueryMount(node) {
		this.jQ = jQuery(node);
		
		GLOBALS.listofnetworksSO = new POPUPCONTAINER_SO_2_0(
			this.jQ,
			GLOBALS.popupSpaceSupplier, 
			GLOBALS.stateEngine.listofnetworksHide,
			POPUPCONTAINER_VO_LIST_2_0
		);
		GLOBALS.spaceObjects.push(GLOBALS.listofnetworksSO);
	}
	
	/*---------------------------------------------------------------------*/
	onClick_listEntry(event) {
		var node = jQuery(event.target).closest(".hitListEntry[data-id]");
		var id = parseInt(node.attr('data-id'));
		GLOBALS.stateEngine.contentShow(id);
	}
} //class

